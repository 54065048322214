body,
html {
  font-size: 32px;
  font-family: "Roboto", sans-serif;
}

#react-app {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.MuiBackdrop-root {
  background-color: initial !important;
}
